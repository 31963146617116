import React, {useState} from 'react';
import MyModal from "../../../../modal/modal";

function ApplicationItemServicesModal({isServiceModalOpen, closeServicesModal,servicesToSelect,handleAddServiceToOrder}) {

    const addService = (parking_has_service_id,cost ) => {
       const serviceData = {
            parking_has_service_id: parking_has_service_id,
            cost: cost
        }
        handleAddServiceToOrder(serviceData)
    }
    return (
        <MyModal isOpen={isServiceModalOpen} onClose={closeServicesModal}  ariaHideApp={false}>
            <div
                className={'scrolling__type modal-content d-flex'}
                style={{maxWidth:'50%',transform:'translate(50%,0)' }}
            >
                <table
                    style={{overflowY: 'auto', maxHeight: '550px'}}
                    className="price-list-table table-services"
                >
                    <thead style={{background:'#536e9b'}}>
                        <tr key={'table-services-123123'}>
                            <th key={'title'} className="price-list-head" style={{color:'#fff'}}>Название</th>
                            <th key={'price'} className={'text-center'} style={{color:'#fff'}}> Цена</th>
                            <th key={'close'}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={()=>{closeServicesModal()}}
                                >
                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="ÐÐºÐ¾Ð½ÐºÐ°">
                                            <path id="Icon"
                                                  d="M5.07219 4.85247C5.44645 4.49449 6.05323 4.49449 6.42748 4.85247L11.4998 9.70429L16.5722 4.85247C16.9464 4.49449 17.5532 4.49449 17.9275 4.85247C18.3017 5.21045 18.3017 5.79085 17.9275 6.14883L12.8551 11.0007L17.9275 15.8525C18.3017 16.2105 18.3017 16.7909 17.9275 17.1488C17.5532 17.5068 16.9464 17.5068 16.5722 17.1488L11.4998 12.297L6.42748 17.1488C6.05323 17.5068 5.44645 17.5068 5.07219 17.1488C4.69794 16.7909 4.69794 16.2105 5.07219 15.8525L10.1445 11.0007L5.07219 6.14883C4.69794 5.79085 4.69794 5.21045 5.07219 4.85247Z"
                                                  fill="#D0D0D0"></path>
                                        </g>
                                    </svg>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {servicesToSelect?.map((service, index)=>{
                        return(
                                <tr key={index}>
                                    <td key={'name'+index}>{service.parking_service.name}</td>
                                    <td key={'cost'+index} className={'text-center'}>{service.cost}</td>
                                    <td key={'add'+index} className={'d-flex justify-content-end align-items-center'}>
                                        <a href="#"
                                           style={{minWidth:'150px'}}
                                           className="btn btn-warning"
                                           onClick={()=>{addService(service?.id,service?.cost)}}
                                        >
                                            Добавить услугу
                                        </a>
                                    </td>
                                </tr>

                        )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </MyModal>
    );
}

export default ApplicationItemServicesModal;