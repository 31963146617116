import React, { useState } from "react";
import Modal from "react-modal";

const MyModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Модальное окно"
      className="modal-block active"
      overlayClassName="modal-overlay"
      portalClassName="my-portal"
      appElement={document.getElementById('root')}
    >
      {children}
    </Modal>
  );
};

export default MyModal;
