import React, {useCallback, useEffect, useState} from "react";
import Preloader from "../../../preloader/preloader";
import ApplicationItem from "./applications-item/applications-item";
import {ToastContainer} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {applicationsSingle} from "../../../../store/applications/applications-single/applicationsSingleSlice";
import {applicationServicesGetById} from "../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {useDispatch} from "react-redux";
import ApplicationItemModal from "./applications-item-modal/applications-item-modal";

function ApplicationsList({applications, setFavorite}) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentModalInfo, setCurrentModalInfo] = useState(null);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

    const isModalOpen = location.pathname.startsWith("/modal/");

    useEffect(() => {
        if (location.pathname.startsWith("/modal/")) {
            const id = location.pathname.split("/modal/")[1];
            if (id) {
                openModal(id);
            }
        }
    }, []);

    const openModal = (id) => {
        dispatch(applicationsSingle(id)).then((resp) => {
            setCurrentModalInfo(resp.payload)
        });
        navigate(`/modal/${id}`);
        let currentApplicationId = id;
        dispatch(applicationServicesGetById({currentApplicationId}))
    }

    const closeModal = () => {
        navigate("/");
        setCurrentModalInfo(null)
    };

    const openServicesModal = () => {
        setIsServiceModalOpen(true)
    }
    const closeServicesModal = () => {
        setIsServiceModalOpen(false)
    }

    return (
        <>
            <section className="car-col">
                <div className="container">
                    <div className="row">
                        {applications && applications.length !== 0 ? (
                            Object.keys(applications).length !== 0 &&
                            applications?.applications.map((item) => {
                                return (
                                    <ApplicationItem
                                        key={item.id}
                                        item={item}
                                        openModal={openModal}
                                        closeModal={closeModal}
                                        isModalOpen={isModalOpen}
                                        currentModalInfo={currentModalInfo}
                                        setFavorite={setFavorite}
                                    />
                                );
                            })
                        ) : (
                            <Preloader/>
                        )}
                    </div>
                </div>
            </section>
            {isModalOpen && currentModalInfo ?
                <ApplicationItemModal
                    single={currentModalInfo}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    setFavorite={setFavorite}
                    openServicesModal={openServicesModal}
                    isServiceModalOpen={isServiceModalOpen}
                    closeServicesModal={closeServicesModal}
                /> : ''}
        </>
    );
}

export default ApplicationsList;
