import React, {useEffect, useLayoutEffect, useRef} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {setApplicationDataSlice} from "../../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";

import {
    applicationsBrand,
    brandReset,
} from "../../../../store/applications/applications-select/application-brand/applicationBrandSlice";
import {
    applicationsGenerations,
    generationReset,
} from "../../../../store/applications/applications-select/application-generations/applicationGenerationsSlice";
import {
    applicationsModel,
    modelReset,
} from "../../../../store/applications/applications-select/application-model/applicationModelSlice";
import {
    applicationsSeries,
    seriesReset,
} from "../../../../store/applications/applications-select/application-series/applicationSeriesSlice";
import {applicationsType} from "../../../../store/applications/applications-select/application-type/applicationTypeSlice";
import {
    applicationsYear,
    yearReset,
} from "../../../../store/applications/applications-select/application-year/applicationYearSlice";
import {
    applicationsEngines,
    enginesReset,
} from "../../../../store/applications/applications-select/applications-engines/applicationEnginesSlice";
import {
    applicationsGears,
    gearsReset,
} from "../../../../store/applications/applications-select/applications-gears/applicationGearsSlice";
import {
    applicationsModifications,
    modificationsReset,
} from "../../../../store/applications/applications-select/applications-modification/applicationModificationsSlice";
import {
    applicationsTransmissions,
    transmissionsReset,
} from "../../../../store/applications/applications-select/applications-transmission/applicationTransmissionsSlice";
import {pts} from "../../../../utils/select-array";
import selectStyle from "../../../../utils/select-styles";
import FileUploadFormEdit from "../../../file-upload/file-edit-upload";
import ImageUploaderEdit from "../../../image-upload/image-edit-upload";
import {
    applicationCarTypes,
    applicationsCreate
} from "../../../../store/applications/applications-create/application-create-get/applicationCreateSlice";
import {prefix} from "react-beautiful-dnd/src/view/data-attributes";
import FileUploadModal from "../../../file-upload/file-upload-modal";

function ApplicationEditStep2(
    {
        colors,
        applications,
        tab,
        setUpdateAppData,
        setAutomaticSelect,
        automaticSelect,
        updateAppData,
        carTypesList
    }) {
    const {appType} = useSelector((state) => state.applicationType);
    const {brand} = useSelector((state) => state.applicationBrand);
    const {model} = useSelector((state) => state.applicationModel);
    const {year} = useSelector((state) => state.applicationYear);
    const {generations} = useSelector((state) => state.applicationGenerations);
    const {series} = useSelector((state) => state.applicationSeries);
    const {modifications} = useSelector(
        (state) => state.applicationModifications
    );
    const {engines} = useSelector((state) => state.applicationEngines);
    const {transmissions} = useSelector(
        (state) => state.applicationTransmissions
    );
    const {gears} = useSelector((state) => state.applicationGears);

    const dispatch = useDispatch();

    const [type, setType] = useState(0);
    const [brandActive, setBrand] = useState(0);
    const [modelActive, setModel] = useState(0);
    const [yearActive, setYear] = useState(0);
    const [generationsActive, setGenerations] = useState(0);
    const [seriesActive, setSeries] = useState(0);
    const [modificationActive, setModification] = useState(0);
    const [enginesActive, setEngines] = useState(0);
    const [transmissionsActive, setTransmissions] = useState(0);
    const [gearsActive, setGears] = useState(0);
    const {carTypes} = useSelector((state) => state.applicationCreate.applications);
    const applicationTypeClick = (func) => {
        setAutomaticSelect(true);
        dispatch(func);
    };

    const [subType, setSubType] = useState(0);
    const [undefinedFieldName, setUndefinedFieldName] = useState([])
    // дублируем информацию о ТС, что бы иметь возможность манипулировать данными при переключении чекбоксов
    const [applicationInfo, setApplicationInfo] = useState({})

    const setUndefinedFieldHandler = (e) => {
        const {name} = e.target
        setUndefinedFieldName((prevState) => {
            if (prevState.includes(name)) {
                return prevState.filter((item) => item !== name)
            } else {
                return [...prevState, name]
            }
        })
        //сбрасываем значение поля введенного вруччную, при переключении чекокса
        setApplicationInfo((prevState) => {
            if (prevState[name] !== null) {
                return {
                    ...prevState,
                    [name]: null, // Set the value of the key to null
                };
            } else {
                return prevState; // No changes needed
            }
        });
    }

    //чекбоксы
    useEffect(() => {
        if (!applications.car_mark_id) {
            setUndefinedFieldName(prevState => [...prevState, 'mark'])
        }
        if (!applications.car_model_id) {
            setUndefinedFieldName(prevState => [...prevState, 'model'])
        }
        if (!applications.year) {
            setUndefinedFieldName(prevState => [...prevState, 'custom_year'])
        }
        if (!applications.car_generation_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_generation'])
        }
        if (!applications.car_modification_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_modification'])
        }
        if (!applications.car_engine_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_engine'])
        }
        if (!applications.car_transmission_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_transmission'])
        }
        if (!applications.car_gear_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_gear'])
        }
        if (!applications.car_series_id) {
            setUndefinedFieldName(prevState => [...prevState, 'car_series'])
        }
    }, [])

    useEffect(() => {
        setApplicationInfo(applications)
    },[applications])

    // Проверяем поколение и если оно пусто то выдаем кузов
    //
    // Прокрутка до определнного места
    const typeRef = useRef(null);
    const markRef = useRef(null);
    const modelRef = useRef(null);
    const yearRef = useRef(null);
    useLayoutEffect(() => {
        if (tab === 1) {
            Promise.resolve()
                .then(() => {
                    typeRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
                })
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .then(() => {
                    markRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
                })
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .then(() => {
                    modelRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                    });
                })
                .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
                .then(() => {
                    yearRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
                });
        }
    }, [tab]);

    //
    useEffect(() => {
        carTypesList && setSubType(carTypesList.filter(carType => carType.id === updateAppData.car_type_id))
    }, [carTypesList])


    useEffect(() => {
        if (tab === 1) {
            //
            dispatch(applicationsType());
            setType(applications?.car_type_id);
            //
            //
            dispatch(applicationsBrand(applications?.car_type_id));
            setBrand(applications?.car_mark_id);
            //
            //
            dispatch(applicationsModel(applications?.car_mark_id));
            setModel(applications?.car_model_id);
            //
            //
            dispatch(applicationsYear(applications?.car_model_id));
            setYear(applications?.year);
            //

            //
            dispatch(
                applicationsGenerations({
                    modelId: applications?.car_model_id,
                    yearId: applications?.year,
                })
            );
            setGenerations(applications?.car_generation_id);
            //

            //
            dispatch(
                applicationsSeries({
                    generetionId: applications?.car_generation_id,
                    modelsId: applications?.car_model_id,
                })
            );
            setSeries(applications?.car_series_id);
            //

            //
            dispatch(
                applicationsModifications({
                    modelId: applications?.car_model_id,
                    seriesId: applications?.car_series_id,
                    yearId: applications?.year,
                })
            );
            setModification(applications?.car_modification_id);
            //

            //
            dispatch(applicationsEngines(applications?.car_modification_id));
            setEngines(applications?.car_engine_id);
            //

            //
            dispatch(applicationsTransmissions(applications?.car_modification_id));
            setTransmissions(applications?.car_transmission_id);
            //

            //
            dispatch(applicationsGears(applications?.car_modification_id));
            setGears(applications?.car_gear_id);
            //
        }
    }, [tab]);

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target ? e.target : e;
        let newValue = value;
        if (type === "checkbox") {
            newValue = checked ? 1 : 0;
        }

        if (name === 'car_type_id') {
            setSubType(carTypesList.filter(carType => carType.id === value))
            setToggleTrailerPrices(true)
        }
        setUpdateAppData({
            ...updateAppData,
            [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        });

        if (name === "car_type_id" && value !== brandActive) {
            setBrand(0);
            setModel(0);
            setYear(0);
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(brandReset());
            dispatch(modelReset());
            dispatch(yearReset());
            dispatch(generationReset());
            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_mark_id: null,
                car_model_id: null,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            })
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_mark_id: null,
                    car_model_id: null,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_mark_id" && value !== brandActive) {
            setModel(0);
            setYear(0);
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(yearReset());
            dispatch(generationReset());
            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_mark: null,
                    car_model: null,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_mark: null,
                car_model_id: null,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_model_id" && value !== modelActive) {
            setYear(0);
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(generationReset());
            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_model: null,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_model: null,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "year" && value !== yearActive) {
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                custom_year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    custom_year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_generation_id" && value !== generationsActive) {
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_generation: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_generation: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_series_id" && value !== seriesActive) {
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_series: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_series: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_modification_id" && value !== modificationActive) {
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_modification: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_modification: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_engine_id" && value !== enginesActive) {
            setTransmissions(0);
            setGears(0);

            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_engine: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_engine: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
        }
        if (name === "car_transmission_id" && value !== transmissionsActive) {
            setGears(0);

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_transmission: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_transmission: null,
                    car_gear_id: null,
                })
            );
        }


        if (name === "car_mark" && value !== brandActive) {
            setBrand(0);
            setModel(0);
            setYear(0);
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(brandReset());
            dispatch(modelReset());
            dispatch(yearReset());
            dispatch(generationReset());
            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_mark_id: null,
                    car_model_id: null,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_mark_id: null,
                car_model_id: null,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_model" && value !== brandActive) {
            setModel(0)
            setYear(0);
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(generationReset());
            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_model_id: null,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_model_id: null,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "custom_year" && value !== yearActive) {
            setGenerations(0);
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(seriesReset());
            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());

            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue :newValue,
                year: null,
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : newValue,
                    year: null,
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            )
        }
        if (name === "car_generation" && value !== generationsActive) {
            setSeries(0);
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(modificationsReset());
            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_generation_id: null,
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_generation_id: null,
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_series" && value !== seriesActive) {
            setModification(0);
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(enginesReset());
            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_series_id: null,
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_series_id: null,
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_modification" && value !== modificationActive) {
            setEngines(0);
            setTransmissions(0);
            setGears(0);

            dispatch(transmissionsReset());
            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_modification_id: null,
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_modification_id: null,
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_engine" && value !== enginesActive) {
            setTransmissions(0);
            setGears(0);

            dispatch(gearsReset());
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_engine_id: null,
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_engine_id: null,
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_transmission" && value !== transmissionsActive) {
            setGears(0);
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_transmission_id: null,
                    car_gear_id: null,
                })
            );
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_transmission_id: null,
                car_gear_id: null,
            });
        }
        if (name === "car_gear" && value !== gearsActive) {
            setGears(0);
            setUpdateAppData({
                ...updateAppData,
                [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                car_gear_id: null,
            });
            dispatch(
                setApplicationDataSlice({
                    [name]: isNaN(newValue) ? newValue : parseInt(newValue),
                    car_gear_id: null,
                })
            );
        }
    };
    //
    // Передаем  нужный color в defaultValue
    let colorDefault = {};
    for (let i = 0; i < colors.length; i++) {
        if (colors[i].value === applications?.color) {
            colorDefault = colors[i];
        }
    }

    //

    // Делаем проверку на количество и если одно то запускаем код

    // Модель
    useEffect(() => {
        // Если есть одно поколение
        const fetchData = async () => {
            if (model && model.length === 1) {
                const item = model[0];
                const modelsId = item.id;
                setModel(item.id);
                await dispatch(applicationsYear(modelsId));
                setUpdateAppData({
                    ...updateAppData,
                    car_model_id: isNaN(modelsId) ? modelsId : parseInt(modelsId),
                });
            }
        };
        if (modelActive && automaticSelect) {
            fetchData();
        }
    }, [model]);
    //

    // Год
    useEffect(() => {
        // Если есть одно поколение
        const fetchData = async () => {
            if (year && year.length === 1) {
                const item = year[0]; // Получаем первый и единственный элемент массива year
                const modelId = modelActive;
                const yearId = item.id;
                await dispatch(applicationsGenerations({modelId, yearId}));
                setUpdateAppData({
                    ...updateAppData,
                    year: isNaN(yearId) ? yearId : parseInt(yearId),
                });
            }
        };
        if (modelActive && automaticSelect) {
            fetchData();
        }
    }, [year, modelActive]);
    //
    // ПОКОЛЕНИЕ
    useEffect(() => {
        // Если есть одно поколение
        const fetchData = async () => {
            if (generations && generations.length === 1) {
                const item = generations[0];
                const generetionId = item.id;
                await dispatch(applicationsSeries({generetionId, modelActive}));
                setUpdateAppData({
                    ...updateAppData,
                    car_generation_id: isNaN(item.id) ? item.id : parseInt(item.id),
                });
            }
        };
        if (modelActive && automaticSelect) {
            fetchData();
        }
    }, [generations]);
    //

    // КУЗОВ
    useEffect(() => {
        // Если есть одно поколение
        const fetchData = async () => {
            if (series && series.length === 1) {
                const item = series[0];
                const modelId = modelActive;
                const seriesId = item.id;
                const yearId = yearActive;
                await dispatch(
                    applicationsModifications({modelId, seriesId, yearId})
                );
                setUpdateAppData({
                    ...updateAppData,
                    car_series_id: isNaN(seriesId) ? seriesId : parseInt(seriesId),
                });
            }
        };
        if (modelActive && yearActive && automaticSelect) {
            fetchData();
        }
    }, [series]);
    //

    // МОДИФИКАЦИЯ
    useEffect(() => {
        // Если есть одно поколение
        if (modifications && automaticSelect) {
            if (modifications.length === 1) {
                const item = modifications[0]; // Получаем первый и единственный элемент массива generations
                setModification(item.id);

                dispatch(applicationsEngines(item.id));
                setUpdateAppData({
                    ...updateAppData,
                    car_modification_id: isNaN(item.id) ? item.id : parseInt(item.id),
                });
            }
        }
    }, [modifications]);
    //
    // ДВИГАТЕЛЬ
    useEffect(() => {
        // Если есть одно поколение
        if (engines && automaticSelect) {
            if (engines.length === 1) {
                const item = engines[0];
                const enginesID = item.id;
                dispatch(applicationsTransmissions(modificationActive));
                setUpdateAppData({
                    ...updateAppData,
                    car_engine_id: isNaN(enginesID) ? enginesID : parseInt(enginesID),
                });
            }
        }
    }, [engines, modificationActive]);
    //
    // КПП
    useEffect(() => {
        // Если есть одно поколение
        if (transmissions && automaticSelect) {
            if (transmissions.length === 1) {
                const item = transmissions[0];
                const transmissionsId = item.id;
                dispatch(applicationsGears(modificationActive));
                setUpdateAppData({
                    ...updateAppData,
                    car_transmission_id: isNaN(transmissionsId)
                        ? transmissionsId
                        : parseInt(transmissionsId),
                });
            }
        }
    }, [transmissions]);
    //

    // Привод
    useEffect(() => {
        // Если есть одно поколение
        if (gears && automaticSelect) {
            if (gears.length === 1) {
                const item = gears[0];
                const gearsId = item.id;
                setUpdateAppData({
                    ...updateAppData,
                    car_gear_id: isNaN(gearsId) ? gearsId : parseInt(gearsId),
                });
            }
        }
    }, [gears]);
    //

    // Тех.состояние
    const [checkboxStates, setCheckboxStates] = useState({
        condition_engine: applications?.condition_engine
            ? applications?.condition_engine
            : [],
        condition_transmission: applications?.condition_transmission
            ? applications?.condition_transmission
            : [],
        condition_electric: applications?.condition_electric
            ? applications?.condition_electric
            : [],
        condition_gear: applications?.condition_gear
            ? applications?.condition_gear
            : [],
    });

    const handleCheckboxChange = (name, value) => {
        setCheckboxStates((prevState) => {
            if (Array.isArray(prevState[name])) {
                // Если значение уже является массивом
                if (prevState[name].includes(value)) {
                    // Удаляем значение из массива
                    return {
                        ...prevState,
                        [name]: prevState[name].filter((item) => item !== value),
                    };
                } else {
                    // Добавляем значение в массив
                    return {
                        ...prevState,
                        [name]: [...prevState[name], value],
                    };
                }
            } else {
                // Если значение не является массивом
                return {
                    ...prevState,
                    [name]: [value],
                };
            }
        });
    };

    useEffect(() => {
        setUpdateAppData({...updateAppData, ...checkboxStates});
    }, [checkboxStates]);

    // Для получение роли
    const {user} = useSelector((state) => state.auth);
    //

    // Поиск при выборке машин
    //
    const [searchType, setSearchType] = useState("");
    const [searchMark, setSearchMark] = useState("");
    const [searchModel, setSearchModel] = useState("");
    const [searchYear, setSearchYear] = useState("");
    const [trailerSubType, setTrailerSubType] = useState(0);
    const [toggleTrailerPrices, setToggleTrailerPrices] = useState(false)
    const filterByType = (e) => {
        const {value} = e.target;
        setSearchType(value);
    };

    const filterByMark = (e) => {
        const {value} = e.target;
        setSearchMark(value);
    };
    useEffect(() => {
        setTrailerSubType(carTypes?.filter(carType => carType.id === 4))
    }, [dispatch])

    useEffect(() => {
        if (applications?.car_type_id === 4) {
            setToggleTrailerPrices(true)
        }
    }, [])
    const filterByModel = (e) => {
        const {value} = e.target;
        setSearchModel(value);
    };

    const filterByYear = (e) => {
        const {value} = e.target;
        setSearchYear(value);
    };
    useEffect(() => {
        dispatch(applicationsCreate())
    }, []);


    return (
        <div className="row no-gutters " id="v-pills-2">
            <div className={'prise_parking'}>
                <div>
                    Стоянка: {applications.parking.title}
                </div>
                {toggleTrailerPrices &&
                <div className={'prise_parking__right'}>
                    <span>Стоимость:</span>
                    <div className="d-flex radio-check-list">
                        {subType[0]?.car_subtypes ? subType[0].car_subtypes.map((subTypeItem, index) => {
                                return <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap custom_radio">
                                        <input
                                            type="radio"
                                            name="car_subtype_id"
                                            defaultValue={parseInt(subTypeItem.id)}
                                            defaultChecked={parseInt(subTypeItem.id) === applications.car_subtype_id ? true : false}
                                            onChange={handleInputChange}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>{subTypeItem.name}: {subTypeItem.price}руб.</span>
                                    </label>
                                </div>
                            }) :
                            <div className="mt-2 mb-3">
                                <label className="switch-radio-wrap custom_radio">

                                    <span> {subType[0]?.price}руб.</span>
                                </label>
                            </div>
                        }
                    </div>
                </div>
                }

            </div>
            <div className="col-md-12 main-col">
                {/* Марка и модель */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Марка и модель</div>
                    <div className="row mr-offset-20">
                        <div className="col-12">
                            <div className="tabform__cartlist w-100 d-flex">
                                {/* Тип Авто */}
                                <fieldset
                                    className="tabform__cart select first-cart car_type_id fieldset new-style-model"
                                    id="types"
                                >
                                    <legend className="legend">
                                        Тип авто...
                                        <span className="mob-arrow"></span>
                                    </legend>
                                    <div className="tabform__mob-dd type-card">
                                        <input
                                            type="text"
                                            placeholder="Поиск"
                                            className="select-search"
                                            onChange={(e) => filterByType(e)}
                                        />
                                        <ul className="select-list tabform__ul type-list">
                                            {carTypesList &&
                                            carTypesList.length !== 0 &&
                                            carTypesList
                                                .filter((item) =>
                                                    item.name
                                                        .toLowerCase()
                                                        .includes(searchType.toLowerCase())
                                                )
                                                .map((item, index) => {
                                                    return (
                                                        item.id !== 27 && <li
                                                            ref={type === item.id ? typeRef : null}
                                                            className={`select-item tabform__li ${
                                                                type === item.id ? "active" : ""
                                                            }`}
                                                            key={index}
                                                            onClick={() => {
                                                                setType(item.id);
                                                                applicationTypeClick(
                                                                    applicationsBrand(item.id)
                                                                );
                                                                handleInputChange({
                                                                    name: "car_type_id",
                                                                    value: item.id,
                                                                });
                                                            }}
                                                        >
                                                            <div>{item.name}</div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </fieldset>
                                <>
                                    {/* Марка авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('mark') ? (
                                            <label className="field-style">
                                                <span>Марка авто</span>
                                                <input
                                                    type="text"
                                                    name="car_mark"
                                                    defaultValue={applicationInfo?.car_mark}
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset
                                                className="tabform__cart select car_mark_id fieldset">
                                                <legend className="legend">
                                                    Марка авто <span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                        onChange={(e) => filterByMark(e)}
                                                    />
                                                    <ul className="tabform__ul select-list type-list">
                                                        {brand && brand.length !== 0 ? (
                                                            brand
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toLowerCase()
                                                                        .includes(searchMark.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            ref={
                                                                                brandActive === item.id ? markRef : null
                                                                            }
                                                                            className={`select-item tabform__li ${
                                                                                brandActive === item.id ||
                                                                                brand.length === 1
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                setBrand(item.id);
                                                                                applicationTypeClick(
                                                                                    applicationsModel(item.id)
                                                                                );
                                                                                handleInputChange({
                                                                                    name: "car_mark_id",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите марку авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            <input
                                                className="checkbox-unknown cvin"
                                                type="checkbox"
                                                checked={undefinedFieldName?.includes('mark')}
                                                name="mark"
                                                onChange={setUndefinedFieldHandler}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Модель авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('model') ? (
                                            <label className="field-style">
                                                <span>Модель авто</span>
                                                <input
                                                    type="text"
                                                    name="car_model"
                                                    defaultValue={applicationInfo?.car_model}
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset
                                                className="tabform__cart select car_model_id fieldset ">
                                                <legend className="legend">
                                                    Модель авто <span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                        onChange={(e) => filterByModel(e)}
                                                    />
                                                    <ul
                                                        className="select-list tabform__ul type-list"
                                                        data-placeholder="Выберите марку авто"
                                                    >
                                                        {model && model.length !== 0 ? (
                                                            model
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toLowerCase()
                                                                        .includes(searchModel.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            ref={
                                                                                modelActive === item.id ? modelRef : null
                                                                            }
                                                                            className={`select-item tabform__li ${
                                                                                modelActive === item.id ||
                                                                                model.length === 1
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                setModel(item.id);
                                                                                applicationTypeClick(
                                                                                    applicationsYear(item.id)
                                                                                );
                                                                                handleInputChange({
                                                                                    name: "car_model_id",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите модель авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            <input
                                                className="checkbox-unknown cvin"
                                                type="checkbox"
                                                checked={undefinedFieldName?.includes('mark') ||
                                                undefinedFieldName?.includes('model')}
                                                name="model"
                                                onChange={setUndefinedFieldHandler}
                                            />
                                            <span className="switcher-radio"></span>
                                            <span> Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Год авто */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ? (
                                            <label className="field-style">
                                                <span>Год</span>
                                                <input
                                                    type="text"
                                                    name="custom_year"
                                                    defaultValue={applicationInfo?.custom_year}
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset
                                                className="tabform__cart select year fieldset">
                                                <legend className="legend">
                                                    Год авто.<span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        onChange={(e) => filterByYear(e)}
                                                        className="select-search"
                                                    />
                                                    <ul className="select-list tabform__ul type-list">
                                                        {year && year.length !== 0 ? (
                                                            year
                                                                .filter((item) =>
                                                                    item.name
                                                                        .toString()
                                                                        .toLowerCase()
                                                                        .includes(searchYear.toLowerCase())
                                                                )
                                                                .map((item, index) => {
                                                                    return (
                                                                        <li
                                                                            ref={
                                                                                yearActive === item.id ? yearRef : null
                                                                            }
                                                                            className={`select-item tabform__li ${
                                                                                Number(updateAppData.year) ===
                                                                                Number(item.id) || year.length === 1
                                                                                    ? "active"
                                                                                    : ""
                                                                            }`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                setYear(item.id);
                                                                                applicationTypeClick(
                                                                                    applicationsGenerations({
                                                                                        modelId: modelActive,
                                                                                        yearId: item.id,
                                                                                    })
                                                                                );
                                                                                handleInputChange({
                                                                                    name: "year",
                                                                                    value: item.id,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <div>{item.name}</div>
                                                                        </li>
                                                                    );
                                                                })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите год авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        checked={undefinedFieldName?.includes('custom_year')}
                                                        name="custom_year"
                                                        onChange={setUndefinedFieldHandler}

                                                    />
                                                    <span className="switcher-radio"></span>
                                                </>)}
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* Поколение и модификация */}
                {type === 27 ? (
                    ""
                ) : (
                    <div className="inner-page__item">
                        <div className="inner-item-title">Поколение и модификация</div>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="tabform__cartlist spec-styles
                                    tabform__cartlist-col-3 d-flex
                                    justify-content-between gap-4 no_margin cstm-height">
                                    {/* Поколение  */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ? (
                                            <label className="field-style">
                                                <span>Поколение</span>
                                                <input
                                                    type="text"
                                                    defaultValue={applicationInfo?.car_generation}
                                                    name="car_generation"
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset className="tabform__cart select year fieldset">
                                                <legend className="legend">
                                                    Поколение.. <span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                    />
                                                    <ul className="select-list tabform__ul type-list">
                                                        {generations && generations.length !== 0 ? (
                                                            generations.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        className={`select-item tabform__li ${
                                                                            generationsActive === item.id ||
                                                                            generations.length === 1
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        key={index}
                                                                        onClick={() => {
                                                                            setGenerations(item.id);
                                                                            applicationTypeClick(
                                                                                applicationsSeries({
                                                                                    generetionId: item.id,
                                                                                    modelsId: modelActive,
                                                                                })
                                                                            );
                                                                            handleInputChange({
                                                                                name: "car_generation_id",
                                                                                value: item.id,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div>{item.name}</div>
                                                                    </li>
                                                                );
                                                            })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите поколение авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        checked={undefinedFieldName?.includes('car_generation')}
                                                        name="car_generation"
                                                        onChange={setUndefinedFieldHandler}

                                                    />
                                                    <span className="switcher-radio"></span>
                                                </>)}
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Кузов  */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_series') ? (
                                            <label className="field-style">
                                                <span>Кузов...</span>
                                                <input
                                                    type="text"
                                                    name="car_series"
                                                    defaultValue={applicationInfo?.car_series}
                                                    onChange={handleInputChange}
                                                    placeholder="Не указан"
                                                />
                                            </label>
                                        ) : (
                                            <fieldset className="tabform__cart select car_series fieldset">
                                                <legend className="legend">
                                                    Кузов...<span className="mob-arrow"></span>
                                                </legend>
                                                <div className="tabform__mob-dd type-card">
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        className="select-search"
                                                    />
                                                    <ul className="select-list tabform__ul type-list">
                                                        {series && series.length !== 0 ? (
                                                            series.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        className={`select-item tabform__li ${
                                                                            seriesActive === item.id ||
                                                                            series.length === 1
                                                                                ? "active"
                                                                                : ""
                                                                        }`}
                                                                        key={index}
                                                                        onClick={() => {
                                                                            setSeries(item.id);
                                                                            applicationTypeClick(
                                                                                applicationsModifications({
                                                                                    modelId: modelActive,
                                                                                    seriesId: item.id,
                                                                                    yearId: yearActive,
                                                                                })
                                                                            );
                                                                            handleInputChange({
                                                                                name: "car_series_id",
                                                                                value: item.id,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div>{item.name}</div>
                                                                    </li>
                                                                );
                                                            })
                                                        ) : (
                                                            <li className="placeholder statuspink">
                                                                Выберите кузов авто
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </fieldset>
                                        )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ||
                                            undefinedFieldName?.includes('car_generation') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="car_series"
                                                        checked={undefinedFieldName?.includes('car_series')}
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </>)}
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Модификация */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style">
                                            <span>Модификация...</span>
                                            <input
                                                type="text"
                                                defaultValue={applicationInfo?.car_modification}
                                                name="car_modification"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select car_mark_id fieldset"
                                            id="modifications"
                                        >
                                            <legend className="legend">
                                                Модификация... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul className="select-list tabform__ul type-list">
                                                    {modifications && modifications.length !== 0 ? (
                                                        modifications.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        modificationActive === item.id ||
                                                                        modifications.length === 1
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setModification(item.id);
                                                                        applicationTypeClick(
                                                                            applicationsEngines(item.id)
                                                                        );
                                                                        handleInputChange({
                                                                            name: "car_modification_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите модификацию авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ||
                                            undefinedFieldName?.includes('car_generation') ||
                                            undefinedFieldName?.includes('car_series') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="car_modification"
                                                        checked={undefinedFieldName?.includes('car_modification')}
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </> )  }
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Двигатель */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_engine') ||
                                        undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style">
                                            <span>Двигатель...</span>
                                            <input
                                                type="text"
                                                defaultValue={applicationInfo?.car_engine}
                                                name="car_engine"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select engines fieldset"
                                            id="engines"
                                        >
                                            <legend className="legend">
                                                Двигатель... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />

                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {engines && engines.length !== 0 ? (
                                                        engines.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        enginesActive === item.id ||
                                                                        engines.length === 1
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setEngines(item.id);
                                                                        applicationTypeClick(
                                                                            applicationsTransmissions(
                                                                                modificationActive
                                                                            )
                                                                        );
                                                                        handleInputChange({
                                                                            name: "car_engine_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите двигатель авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ||
                                            undefinedFieldName?.includes('car_generation') ||
                                            undefinedFieldName?.includes('car_modification') ||
                                            undefinedFieldName?.includes('car_series') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="car_engine"
                                                        checked={undefinedFieldName?.includes('car_engine')}
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </> )  }
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* КПП */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_engine') ||
                                        undefinedFieldName?.includes('car_transmission') ||
                                        undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style">
                                            <span>КПП...</span>
                                            <input
                                                type="text"
                                                defaultValue={applicationInfo?.car_transmission}
                                                name="car_transmission"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset className="tabform__cart select transmissions fieldset">
                                            <legend className="legend">
                                                КПП... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />

                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {transmissions && transmissions.length !== 0 ? (
                                                        transmissions.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        transmissionsActive === item.id ||
                                                                        transmissions.length === 1
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setTransmissions(item.id);
                                                                        applicationTypeClick(
                                                                            applicationsGears(modificationActive)
                                                                        );
                                                                        handleInputChange({
                                                                            name: "car_transmission_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите КПП авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ||
                                            undefinedFieldName?.includes('car_generation') ||
                                            undefinedFieldName?.includes('car_modification') ||
                                            undefinedFieldName?.includes('car_engine') ||
                                            undefinedFieldName?.includes('car_series') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="car_transmission"
                                                        checked={undefinedFieldName?.includes('car_transmission')}
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </> )  }
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}

                                    {/* Привод  */}
                                    <div className="car__mark-with-undefined new-style-model">
                                        {undefinedFieldName?.includes('model') ||
                                        undefinedFieldName?.includes('mark') ||
                                        undefinedFieldName?.includes('custom_year') ||
                                        undefinedFieldName?.includes('car_generation') ||
                                        undefinedFieldName?.includes('car_modification') ||
                                        undefinedFieldName?.includes('car_engine') ||
                                        undefinedFieldName?.includes('car_transmission') ||
                                        undefinedFieldName?.includes('car_gear') ||
                                        undefinedFieldName?.includes('car_series') ? (
                                        <label className="field-style">
                                            <span>Привод...</span>
                                            <input
                                                type="text"
                                                defaultValue={applicationInfo?.car_gear}
                                                name="car_gear"
                                                onChange={handleInputChange}
                                                placeholder="Не указан"
                                            />
                                        </label>
                                    ) : (
                                        <fieldset
                                            className="tabform__cart select gears fieldset"
                                            id="gears"
                                        >
                                            <legend className="legend">
                                                Привод... <span className="mob-arrow"></span>
                                            </legend>
                                            <div className="tabform__mob-dd type-card">
                                                <input
                                                    type="text"
                                                    placeholder="Поиск"
                                                    className="select-search"
                                                />
                                                <ul
                                                    className="select-list tabform__ul type-list"
                                                    data-placeholder="Выберите двигатель авто"
                                                >
                                                    {gears && gears.length !== 0 ? (
                                                        gears.map((item, index) => {
                                                            return (
                                                                <li
                                                                    className={`select-item tabform__li ${
                                                                        gearsActive === item.id || gears.length === 1
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setGears(item.id);
                                                                        applicationTypeClick(
                                                                            applicationsTransmissions(
                                                                                modificationActive
                                                                            )
                                                                        );
                                                                        handleInputChange({
                                                                            name: "car_gear_id",
                                                                            value: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    <div>{item.name}</div>
                                                                </li>
                                                            );
                                                        })
                                                    ) : (
                                                        <li className="placeholder statuspink">
                                                            Выберите привод авто
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </fieldset>
                                    )}
                                        <label className="switch-radio-wrap mt-2">
                                            {undefinedFieldName?.includes('model') ||
                                            undefinedFieldName?.includes('mark') ||
                                            undefinedFieldName?.includes('custom_year') ||
                                            undefinedFieldName?.includes('car_generation') ||
                                            undefinedFieldName?.includes('car_modification') ||
                                            undefinedFieldName?.includes('car_engine') ||
                                            undefinedFieldName?.includes('car_transmission') ||
                                            undefinedFieldName?.includes('car_series') ? '' : (
                                                <>
                                                    <input
                                                        className="checkbox-unknown cvin"
                                                        type="checkbox"
                                                        name="car_gear"
                                                        checked={undefinedFieldName?.includes('car_gear')}
                                                        onChange={setUndefinedFieldHandler}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                </> )  }
                                            <span>Ввести в ручную</span>
                                        </label>
                                    </div>
                                    {/*  */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/*  */}

                {/* Административная информация */}
                {/* <div className="inner-page__item">
          <div className="inner-item-title">Административная информация</div>
          <div className="row">
            <div className="col-6">
              <label className="field-style">
                <span>VIN</span>
                <input className="vin" type="text" placeholder="Не указан" />
              </label>
            </div>
            <div className="col-6">
              <label className="field-style">
                <span>Гос. номер</span>
                <input
                  className="license_plate"
                  id="plate"
                  type="text"
                  placeholder="Не указан"
                />
              </label>
            </div>
          </div>
        </div> */}
                {/*  */}

                {/* Документы */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Документы</div>
                    <div className="row">
                        <div className="col-6">
                            <label className="field-style">
                                <span>СТС</span>
                                <input
                                    type="text"
                                    name="sts"
                                    defaultValue={applications?.sts}
                                    onChange={handleInputChange}
                                    placeholder="Не указан"
                                />
                            </label>
                            {user.role !== "Operator" && (
                                <div className="mt-2">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="checkbox"
                                            name="sts_provided"
                                            onChange={handleInputChange}
                                            defaultChecked={applications?.sts_provided ? true : false}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Принят на хранение</span>
                                    </label>
                                </div>
                            )}
                        </div>

                        <div className="col-6">
                            <div className="d-flex two-field justify-content-start">
                                <label className="field-style  w-100 m-0">
                                    <span>ПТС</span>
                                    <input
                                        onChange={handleInputChange}
                                        name="pts"
                                        type="text"
                                        placeholder="Не указан"
                                        className="w-100"
                                        defaultValue={applications?.pts}
                                    />
                                </label>
                                <label className="field-style select-react__style">
                                    <Select
                                        defaultValue={{
                                            value: applications?.pts_type,
                                            label: applications?.pts_type,
                                            name: "pts_type",
                                        }}
                                        options={pts}
                                        name="pts_type"
                                        styles={selectStyle}
                                        onChange={handleInputChange}
                                        className="page-select"
                                    />
                                </label>
                            </div>
                            {user.role !== "Operator" && (
                                <div className="mt-2">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="checkbox"
                                            name="pts_provided"
                                            onChange={handleInputChange}
                                            defaultChecked={applications?.pts_provided ? true : false}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Принят на хранение</span>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* Информация об автомобиле */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Информация об автомобиле</div>
                    <div className="row">
                        {/* Количество владельцев */}
                        <div className="col-6">
                            <div className="inner-page__item-title">Кол-во владельцев</div>
                            <div className="d-flex radio-check-list">
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="owner_number"
                                            value={parseInt(1)}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.owner_number === 1 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Первый</span>
                                    </label>
                                </div>
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="owner_number"
                                            value={parseInt(2)}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.owner_number === 2 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Второй</span>
                                    </label>
                                </div>
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="owner_number"
                                            value={3}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.owner_number === 3 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Третий и более</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*  */}

                        {/* Количество ключей */}
                        <div className="col-6">
                            <div className="inner-page__item-title">Кол-во ключей</div>
                            <div className="d-flex radio-check-list">
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="car_key_quantity"
                                            defaultValue={0}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.car_key_quantity === 0 ||
                                                !applications?.car_key_quantity
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>0</span>
                                    </label>
                                </div>
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="car_key_quantity"
                                            defaultValue={1}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.car_key_quantity === 1 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>1</span>
                                    </label>
                                </div>
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="car_key_quantity"
                                            defaultValue={2}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.car_key_quantity === 2 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>2</span>
                                    </label>
                                </div>
                                <div className="mt-2 mb-3">
                                    <label className="switch-radio-wrap">
                                        <input
                                            type="radio"
                                            name="car_key_quantity"
                                            defaultValue={3}
                                            onChange={handleInputChange}
                                            defaultChecked={
                                                applications?.car_key_quantity === 3 ? true : false
                                            }
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>3</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                    </div>
                    <div className="row mt-5">
                        {/* Цвет */}
                        <div className="col-6">
                            <label className="field-style  select-react__style">
                                <span>Цвет</span>
                                <Select
                                    defaultValue={colorDefault}
                                    options={colors}
                                    styles={selectStyle}
                                    className="page-select"
                                    onChange={handleInputChange}
                                />
                            </label>
                        </div>
                        {/*  */}

                        {/* Пробег */}
                        <div className="col-6">
                            <label className="field-style mileage">
                                <span>Пробег</span>
                                <input
                                    type="number"
                                    name="milage"
                                    placeholder="Не указан"
                                    onChange={handleInputChange}
                                    defaultValue={applications?.milage}
                                />
                                <span className="mileage-type">км</span>
                            </label>
                        </div>
                        {/*  */}
                    </div>
                </div>
                {/*  */}
                {/* Тех. состояние */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Тех. состояние</div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row radio-check-list">
                                {/* Неисправности двигателя */}
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_engine"
                                            defaultValue="null"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_engine.includes("null")}
                                            onChange={() => {
                                                if (checkboxStates.condition_engine.includes("null")) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_engine: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_engine: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности двигателя</span>
                                    </label>
                                    {checkboxStates.condition_engine.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Дымность двигателя (густой, белый, сизый, черный)"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Дымность двигателя (густой, белый, сизый, черный)"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Дымность двигателя (густой, белый, сизый, черный)"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Дымность двигателя (густой, белый, сизый, черный)
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Повышенный стук и шум при работе двигателя"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Повышенный стук и шум при работе двигателя"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Повышенный стук и шум при работе двигателя"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Повышенный стук и шум при работе двигателя
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Повышенный шум при работе выхлопной системы"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Повышенный шум при работе выхлопной системы"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Повышенный шум при работе выхлопной системы"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Повышенный шум при работе выхлопной системы
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_engine"
                                                    defaultValue="Подтекание при осмотре подкапотного пространства"
                                                    checked={checkboxStates.condition_engine.includes(
                                                        "Подтекание при осмотре подкапотного пространства"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_engine",
                                                            "Подтекание при осмотре подкапотного пространства"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Подтекание при осмотре подкапотного пространства
                        </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {/*  */}

                                {/* Неисправности КПП */}
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_transmission"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_transmission.includes(
                                                "null"
                                            )}
                                            onChange={() => {
                                                if (
                                                    checkboxStates.condition_transmission.includes("null")
                                                ) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_transmission: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_transmission: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности КПП</span>
                                    </label>
                                    {checkboxStates.condition_transmission.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_transmission"
                                                    defaultValue="Рывки и толчки авто при переключении"
                                                    checked={checkboxStates.condition_transmission.includes(
                                                        "Рывки и толчки авто при переключении"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_transmission",
                                                            "Рывки и толчки авто при переключении"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Рывки и толчки авто при переключении
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_transmission"
                                                    defaultValue="Повышенный шум при переключении"
                                                    checked={checkboxStates.condition_transmission.includes(
                                                        "Повышенный шум при переключении"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_transmission",
                                                            "Повышенный шум при переключении"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Повышенный шум при переключении
                        </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {/*  */}

                                {/* Неисправности электрики */}
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_electric"
                                            defaultValue=""
                                            className="chech-dd"
                                            checked={checkboxStates.condition_electric.includes(
                                                "null"
                                            )}
                                            onChange={() => {
                                                if (
                                                    checkboxStates.condition_electric.includes("null")
                                                ) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_electric: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_electric: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности электрики</span>
                                    </label>
                                    {checkboxStates.condition_electric.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_electric"
                                                    checked={checkboxStates.condition_electric.includes(
                                                        "Ошибки на панели приборов при заведенном ДВС"
                                                    )}
                                                    defaultValue="Ошибки на панели приборов при заведенном ДВС"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_electric",
                                                            "Ошибки на панели приборов при заведенном ДВС"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Ошибки на панели приборов при заведенном ДВС
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_electric"
                                                    checked={checkboxStates.condition_electric.includes(
                                                        "Неправильные команды электроники"
                                                    )}
                                                    defaultValue="Неправильные команды электроники"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_electric",
                                                            "Неправильные команды электроники"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Неправильные команды электроники
                        </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {/*  */}

                                {/* Неисправности ходовой */}
                                <div className="col-3 mt-2 mb-3">
                                    <label className="switch-radio-wrap bold">
                                        <input
                                            type="checkbox"
                                            name="condition_gear"
                                            className="chech-dd"
                                            checked={checkboxStates.condition_gear.includes("null")}
                                            onChange={() => {
                                                if (checkboxStates.condition_gear.includes("null")) {
                                                    // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_gear: [],
                                                    }));
                                                } else {
                                                    // Если основной чекбокс был снят, оставляем массив без изменений
                                                    setCheckboxStates((prevState) => ({
                                                        ...prevState,
                                                        condition_gear: ["null"],
                                                    }));
                                                }
                                            }}
                                        />
                                        <span className="switcher-radio"></span>
                                        <span>Неисправности ходовой</span>
                                    </label>
                                    {checkboxStates.condition_gear.includes("null") && (
                                        <div className="chech-dd-list active">
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_gear"
                                                    defaultValue="Посторонний звук со стороны ходовой"
                                                    checked={checkboxStates.condition_gear.includes(
                                                        "Посторонний звук со стороны ходовой"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_gear",
                                                            "Посторонний звук со стороны ходовой"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Посторонний звук со стороны ходовой
                        </span>
                                            </label>
                                            <label className="switch-radio-wrap d-flex mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="condition_gear"
                                                    defaultValue="Посторонние звуки при вращении рулевого колеса"
                                                    checked={checkboxStates.condition_gear.includes(
                                                        "Посторонние звуки при вращении рулевого колеса"
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            "condition_gear",
                                                            "Посторонние звуки при вращении рулевого колеса"
                                                        )
                                                    }
                                                />
                                                <span className="switcher-radio ml-auto"></span>
                                                <span className="check-box-text">
                          Посторонние звуки при вращении рулевого колеса
                        </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {/*  */}
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* Фотографии */}
                <div className="inner-page__item">
                    <ImageUploaderEdit
                        oldimage={applications?.images}
                        id={applications.id}
                    />
                </div>
                {/*  */}

                {/* Документы */}
                <div className="inner-page__item">
                    {/*<FileUploadFormEdit*/}
                    {/*    applicationId={applications.id}*/}
                    {/*    docs={applications?.docs}*/}
                    {/*        setUpdateAppData={setUpdateAppData}*/}
                    {/*        updateAppData={updateAppData}*/}
                    {/*/>*/}
                    <FileUploadModal
                        docs={applications?.docs}
                        applicationId={applications.id}
                    />
                </div>
                {/*  */}

                {/* Дополнительно */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Дополнительно</div>
                    <div className="field-style">
                        <span>Описание</span>
                        <textarea
                            name="car_additional"
                            onChange={handleInputChange}
                            placeholder="Не указан"
                            className="mw-100"
                            defaultValue={applications?.car_additional}
                        ></textarea>
                    </div>
                </div>
                {/*  */}
            </div>
        </div>
    );
}

export default ApplicationEditStep2;
